
@import '../../static/css/mixins.scss';

.hd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__logo {
    width: 249px;
    height: auto;
  }
  &__menu {
    display: none;
  }
}


.menuModal {
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: 1000;
  &__close {
    position: absolute;
    right:0;
    top:0;
    padding: 8px;
    z-index: 1003;
    cursor: pointer;
  }
  &__mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    z-index: 1001;
  }
  &__body {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1002;
    overflow-y: auto;
    &__lauchApp {
      margin-top: 48px;
    }
  }
  &__logo {
    overflow: initial;
  }
  &__content {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 80px 0;
  }
}


@media (max-width: 859.98px) {
  .hd {
    &__nav {
      display: none;
    }
    &__lauchApp {
      display: none;
    }
    &__menu {
      position: relative;
      display: block;
      cursor: pointer;
    }
  }
}






 
